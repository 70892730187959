.roadmap {
    padding-bottom: 160px;
    overflow-x: hidden;
}

.container {
    max-width: 1280px;
    padding: 0 4rem;
    margin: 0 auto;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin-bottom: 72px;
}

.grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    width: 100%;
    grid-column-gap: 0.625rem;
    position: relative;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.line {
    width: 70%;
    position: absolute;
    top: 44px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
}

.lineMobile {
    display: none;
}

.circle {
    width: 110px;
    height: 110px;
}

.postBtn {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba(29, 30, 41, 1);
    padding: 0.5rem 1rem;
    background-color: rgba(215, 252, 81, 1);
    cursor: pointer;
    transition: all .15s ease;
}

.postBtn:hover,
.postBtn:active {
    background: #ADD718;
    color: #1D1E29;
}

.blockBtn {
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
}

.subtitle {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.description {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: rgba(255, 255, 255, 1);
}

@media (min-width:2000px) {
    .container {
        max-width: 1920px;
    }
    .line {
        visibility: hidden;
    }
}

@media only screen and (max-width: 984px) {
    .roadmap {
        padding-bottom: 122px;
    }

    .container {
        padding: 0 1rem;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 44px;
    }

    .grid {
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        grid-row-gap: 48px;
    }

    .item {
        display: grid;
        grid-template-columns: 90px 1fr;
        grid-template-rows: 90px 1fr;
        grid-column-gap: 1.25rem;
        text-align: center;
    }

    .line {
        display: none;
    }

    .lineMobile {
        display: block;
        position: absolute;
        left: 45px;
        top: 130px;
    }

    .circle {
        width: 90px;
        height: 90px;
    }

    .subtitle {
        margin-top: 0.5rem;
        margin-bottom: 0;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .description {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 2;
        grid-column-end: 3;
        align-self: flex-start;
        text-align: left;
    }
}
